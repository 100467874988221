import { library, dom, config } from '@fortawesome/fontawesome-svg-core';

import { 
	faArrowUp, 
	faCamcorder, 
	faCircle as faCircleLight, 
	faEdit, 
	faEllipsisV, 
	faGlobe, 
	faPlayCircle, 
	faPlus as faPlusLight, 
	faQuoteLeft, 
	faQuoteRight, 
	faSearch as faSearchLight, 
	faSpinner, 
	faSync as faSyncLight, 
	faTimes as faTimesLight, 
	faTimesCircle, 
	faUser as faUserLight, 
} from '@fortawesome/pro-light-svg-icons';

import { 
	faBars,
	faEllipsisV as faEllipsisVRegular,
	faChevronLeft as faChevronLeftRegular, 
	faChevronRight as faChevronRightRegular, 
	faChevronUp as faChevronUpSolid, 
	faLongArrowAltRight as faLongArrowAltRightRegular, 
	faSearch as faSearchRegular, 
	faTimes as faTimesRegular,
	faAngleDoubleRight
} from '@fortawesome/pro-regular-svg-icons';

import { 
	faCircle, 
	faSearch as faSearchSolid, 
	faSpinner as faSpinnerSolid 
} from '@fortawesome/pro-solid-svg-icons';

import { faFacebookF, faYoutube, faFacebookSquare, faTwitter, faTwitterSquare, faLinkedinIn, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';

import { 
	faAt, 
	faCameraMovie,
	faDownload, 
	faEnvelopeOpenText, 
	faEye, 
	faFax, 
	faFile, 
	faFileAlt, 
	faFileArchive, 
	faFileAudio,
	faFileImage, 
	faFileInvoice,
	faFilePdf, 
	faFilePowerpoint, 
	faFileSpreadsheet, 
	faFileVideo,
	faFileWord, 
	faLink, 
	faMapMarkedAlt, 
	faMusic,
	faPhone, 
	faTimes as faTimesDuo,
	faTv,
	faVideo 
} from '@fortawesome/pro-duotone-svg-icons';


config.searchPseudoElements = true;
config.keepOriginalSource = false;
library.add(
	faArrowUp,
	faAt,
	faBars,
	faCameraMovie,
	faChevronLeftRegular,
	faChevronRightRegular,
	faChevronUpSolid,
	faCircle,
	faCircleLight,
	faDownload,
	faEdit,
	faEllipsisV,
	faEllipsisVRegular,
	faEnvelopeOpenText,
	faEye,
	faFacebookF,
	faFax,
	faFile,
	faFileAlt,
	faFileArchive,
	faFileAudio,
	faFileImage, 
	faFileInvoice,
	faFilePdf,
	faFilePowerpoint, 
	faFileSpreadsheet, 
	faFileVideo,
	faFileWord, 
	faGlobe,
	faLink,
	faLinkedin,
	faLongArrowAltRightRegular,
	faMapMarkedAlt,
	faMusic,
	faPhone,
	faPlayCircle,
	faPlusLight,
	faQuoteLeft, 
	faQuoteRight,
	faSearchLight, 
	faSearchRegular,
	faSearchSolid,
	faSpinner,
	faSpinnerSolid,
	faSyncLight,
	faTimesCircle,
	faTimesDuo,
	faTimesLight,
	faTimesRegular,
	faTv,
	faTwitter,
	faUserLight,
	faVideo,
	faAngleDoubleRight
);
dom.i2svg();
dom.watch();