import "./bootstrap";
import "./fontawesome";

import ExternalLinks  from "./externallinks";
(new ExternalLinks('body')).addLinkTarget();

let popups = document.querySelectorAll('[data-mfp-type]');
if(popups.length) {

    import(/* webpackChunkName: "magnific" */ './magnificpopup')
        .then((Magnific) => {
            let magnific =  new Magnific.default(popups);
            magnific.initialize()
        })

}

let forms = document.getElementsByClassName('needs-validation');
if(forms.length) {
    import(/* webpackChunkName: "forms" */ './form')
        .then((Forms) => {
            let form =  new Forms.default(forms);
            form.submitButton().validate();
        })
}

let shares = document.querySelectorAll('[data-share-default]');
if(shares.length) {
    import(/* webpackChunkName: "share" */ './share')
        .then((ShareHandler) => {
            for (let i = 0; i < shares.length; i++) {
                const share = shares[i];
                (new ShareHandler.default(share)).initialize();
            }
        })
}

let glides = document.getElementsByClassName('glide');
let lazyImages = document.getElementsByClassName('lazyload').length;

const fireGlider = () => {
    import(/* webpackChunkName: "glider" */ './glide')
        .then((Glider) => {
            console.log('Glider loaded')
            let glide = new Glider.default(glides);
            glide.initialize();
        });
};

if (glides.length) { 
    fireGlider(); 
    // var firstSlide = $('.glide__slide').first()
    // var lastSlide = $('.glide__slide:last').last()
    // if ($(firstSlide).hasClass('glide__slide--active')) {
    //     $('.glide__arrow--prev').addClass('d-none')
    // }
}
        
// let lazyImages = document.getElementsByClassName('lazyload').length;
if(document.querySelectorAll('.lazyload').length) {
    import(/* webpackChunkName: "lazysizes" */ './lazysizes')
        .then(() => {
            // wait for lazysizes before firing glider
            console.log('loaded lazysizes!');
            if (glides.length) {
                fireGlider();
            }
        })
}

// scroll check for navigation/other elements
// that require body "scrolled" class

// initial check 
// var scrollpos = window.scrollY;
// if (scrollpos > 80) {
//     document.body.classList.add("scrolled");
// } 
// onscroll check
// window.addEventListener('scroll', function(){ 
//     scrollpos = window.scrollY;

//     if (scrollpos > 80) {
//         document.body.classList.add("scrolled");
//     } else {
//         document.body.classList.remove("scrolled");
//     }
//     // console.log(scrollpos);
// });

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}

// $('body').on( 'click tap', '.navbar-toggler', function(e) {
// 	var myOffcanvas = $('#navbarSite')[0];
//     var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);
//     e.stopPropagation();
//     bsOffcanvas.toggle();
//     console.log('hit');
// }); 